import axios from "axios";

import { getCSRFToken } from "../auth/auth";

export const getPhoto = (id, size, onSuccess, onError) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/photos/${id}/image?fullsize=${size}`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
        responseType: "blob",
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};
