import React, { Suspense } from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
const AllAlbumsCom = React.lazy(() =>
  import("../../components/admin/all-albums/AllAlbumsCom")
);

function AllAlbums(props) {
  return (
    <AdminLayout>
      <Suspense fallback={<div>Loading...</div>}>
        <AllAlbumsCom {...props} />
      </Suspense>
    </AdminLayout>
  );
}

export default AllAlbums;
