import React from "react";
import { withRouter } from "react-router-dom";

import Logo from "../../../images/logo.png";

function LogoCol(props) {
  return (
    <div className="col-12 col-md-6 logo-col">
      <div className="logo-back-div">
        {/* <span onClick={() => props.history.goBack()}>
          <i class="fas fa-long-arrow-alt-left"></i> Back
        </span> */}
        {/* <h1 className="text-white text-center">pix.lk</h1> */}
        <img src={Logo} className="logo" alt="arti" />
      </div>
    </div>
  );
}

export default withRouter(LogoCol);
