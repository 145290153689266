import axios from "axios";

export const refreshCSRF = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/generic/refresh_csrf`, {
      withCredentials: true,
    })
    .then((res) => {
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
      ) {
        localStorage.setItem("csrf_t", res.data.id);
      }

      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};
