import React, { useState, useEffect } from "react";
import classnames from "classnames";

import { getAllPhotoCategories } from "../../../api/PublicPhotosAPI";

import Close from "../../../images/close-white.png";
import { Modal } from "react-bootstrap";

function AcceptModal(props) {
  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   getAllPhotoCategories(
  //     (data) => {
  //       setCategories(data.data);
  //     },
  //     (error) => {}
  //   );
  // }, []);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      size="md"
    >
      <Modal.Body>
        {/* <div
          className="modal fade"
          id="acceptModal"
          tabIndex="-1"
          aria-labelledby="acceptModalLabel"
          aria-hidden="true"
        > */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="acceptModalLabel">
                Are you sure?
              </h5>

              {/* <img
                src={Close}
                alt="close"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                width="50px"
                style={{ cursor: "pointer" }}
                id="acceptModalClose"
              /> */}
            </div>
            <div className="modal-body">
              <p style={{ marginBottom: 25 }}>
                Do you want to accept this photo?
              </p>

              <div className="form-group col p-0 mb-3">
                <label htmlFor="photoName">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="photoName"
                  name="photoName"
                  value={props.photoName}
                  onChange={(e) => props.setPhotoName(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="form-group col p-0 mb-3">
                <label htmlFor="photoTags">Tags</label>
                <input
                  type="text"
                  className="form-control"
                  id="photoTags"
                  name="photoTags"
                  value={props.photoTags}
                  onChange={(e) => props.setPhotoTags(e.target.value)}
                  autoComplete="off"
                />
              </div>
              {/* <div className="form-group col p-0 mb-3">
                <label htmlFor="photoCat">Category</label>
                <select
                  className="form-control"
                  id="photoCat"
                  name="photoCat"
                  value={props.photoCat}
                  onChange={(e) => props.setPhotoCat(e.target.value)}
                >
                  {categories &&
                    categories.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </select>
              </div> */}
              <div className="form-group col p-0 mb-3">
                <label htmlFor="photoDesc">Description</label>
                <textarea
                  className="form-control"
                  id="photoDesc"
                  name="photoDesc"
                  value={props.photoDesc}
                  onChange={(e) => props.setPhotoDesc(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer border-0 p-2">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={props.onHide}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => props.handleAcceptPhoto()}
                style={{ width: 95 }}
              >
                {props.loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Accept"
                )}
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
}

export default AcceptModal;
