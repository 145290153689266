import axios from "axios";
import qs from "qs";

import { getCSRFToken } from "../auth/auth";

export const rejectPhoto = (id, onSuccess, onError) => {
  console.log(id);

  const data = qs.stringify({
    accept: false,
  });

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/admin/photos/${id}/accept`,
      data,
      {
        withCredentials: true,
        "Content-Type": "application/x-www-form-urlencoded;",
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};
