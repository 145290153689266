import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { getCSRFToken } from "../../../auth/auth";

import DashboardCard from "./DashboardCard";
import { getUserProfile } from "../../../api/getAnUser";

function DashboardCom() {
  const [pendingsAds, setPendingsAds] = useState([]);
  const [pendingsApprovals, setPendingsApprovals] = useState([]);
  const [createdAds, setCreatedAds] = useState([]);
  const [agents, setAgents] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    getUserProfile(
      (data) => {
        setUser(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  // useEffect(() => {
  //   // pending ads
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/v1/agent_posts/scanned`, {
  //       headers: {
  //         Authorization: `Bearer ${getCSRFToken()}`,
  //       },
  //     })
  //     .then((res) => {
  //       const pendings = res.data.filter((el) => el.post === undefined);
  //       setPendingsAds(pendings);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   // pending approvals
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/v1/admin/agent_payments/scanned`, {
  //       headers: {
  //         Authorization: `Bearer ${getCSRFToken()}`,
  //       },
  //     })
  //     .then((res) => {
  //       const onlyPendings = res.data.filter((el) => el.status === "SUBMITTED");

  //       setPendingsApprovals(onlyPendings);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   // created ads
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/v1/agent_posts/scanned`, {
  //       headers: {
  //         Authorization: `Bearer ${getCSRFToken()}`,
  //       },
  //     })
  //     .then((res) => {
  //       const filterByPost = res.data.filter((el) => el.post !== undefined);
  //       setCreatedAds(filterByPost);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   // all agents
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/v1/admin/agents`, {
  //       headers: {
  //         Authorization: `Bearer ${getCSRFToken()}`,
  //       },
  //     })
  //     .then((res) => {
  //       setAgents(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  return (
    <div className="content-col dashboard-page">
      <div className="row m-0">
        <div className="col p-0">
          <h1 className="page-heading">Welcome</h1>
          <div className="page-body">
            <h2>
              {user.data
                ? `${user.data.first_name} ${user.data.last_name}`
                : null}
            </h2>
            <h3>{user.data ? user.data.role : null}</h3>
            <h3>{user.data ? user.data.email : null}</h3>
          </div>

          {/* <DashboardCard
            count={pendingsAds.length}
            title="Pending Ads"
            color="#f8ad4a"
            link="/dashboard/agents/new"
          />
          <DashboardCard
            count={pendingsApprovals.length}
            title="Pending Approvals"
            color="#ff6968"
            link="/dashboard/pending-bank-approvals"
          />
          <DashboardCard
            count={createdAds.length}
            title="No of Ads Created"
            color="#4caf50"
            link="/dashboard/agents/created"
          />
          <DashboardCard
            count={agents.length}
            title="No of Agents"
            color="#2196f3"
            link="/dashboard/all-agents"
          /> */}
        </div>
      </div>
    </div>
  );
}

export default DashboardCom;
