import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { orderBy } from "lodash";
import { useHistory } from "react-router-dom";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { getAllUsers, verifyUser } from "../../../api/userAPI";

function AllUsersCom() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortKey, setSortKey] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");

  const itemsPerPage = 20;

  useEffect(() => {
    getAllUsers(
      (data) => {
        data.map(
          (s, i) => (s.fullName = s.first_name.concat(" ", s.last_name))
        );
        setUsers(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const onChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const filterBySearch = users.filter((el) => {
    if (search) {
      return (
        (el.fullName &&
          el.fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (el.username &&
          el.username.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      );
    } else {
      return el;
    }
  });

  //sorting
  const onSort = (row) => {
    console.log(row);
    console.log(sortKey);

    if (row == sortKey) {
      if (sortOrder == "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    } else {
      setSortOrder("asc");
    }
    setSortKey(row);
  };

  const sorted = orderBy(filterBySearch, [sortKey], [sortOrder]);

  // pagination
  const noOfPages = Math.ceil(sorted.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = sorted.slice(indexOfFirst, indexOfLast);

  const headers = [
    { key: "created_at", label: "Created", sortingKey: "created_at" },
    { key: "username", label: "Username", sortingKey: "username" },
    { key: "fullName", label: "Name" },
    { key: "livephotos", label: "Live Photos" },
    {
      key: "inreview",
      label: "In Review",
      sortingKey: "upload_stats.in_review",
    },
    { key: "status", label: "Status" },
  ];

  const renderSortIcon = (column) => {
    if (column != sortKey) {
      return null;
    }
    if (sortOrder === "asc") return <i class="fas fa-sort-up ml-2"></i>;
    return <i class="fas fa-sort-down ml-2"></i>;
  };

  const tableheaderStyles = (a) => {
    let headerStyle = "";
    return a ? (headerStyle = "pointer") : null;
  };

  const tableheaderAlignment = (a) => {
    let headerAlignment = "";
    if (a == "livephotos" || a == "inreview") return "end";
    if (a == "status") return "center";
  };

  const history = useHistory();

  const handleRowClick = (row) => {
    history.push({
      pathname: `/dashboard/all-albums`,
      search: `?uid=${row.username}`,
      state: { row },
    });
  };

  return (
    <div className="content-col">
      <Breadcrumb title="All Users" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                <label className="sr-only" htmlFor="search-by-id">
                  Search by name or code or city
                </label>
                <div className="input-group mb-0" style={{ width: "900px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    id="search-by-id"
                    placeholder="Name / Username"
                    value={search}
                    onChange={onChange}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <i
                    className="fas fa-search"
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "8px",
                      color: "#949494",
                      zIndex: 3,
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content py-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  {headers.map((row) => {
                    return (
                      <td
                        onClick={
                          row.sortingKey ? () => onSort(row.sortingKey) : ""
                        }
                        className="border-0"
                        key={row.key}
                        style={{
                          cursor: tableheaderStyles(row.sortingKey),
                          textAlign: tableheaderAlignment(row.key),
                        }}
                      >
                        {row.label}
                        {renderSortIcon(row.sortingKey)}
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody style={{ cursor: "pointer" }}>
                {currentItems.length ? (
                  currentItems.map((el, i) => (
                    <tr key={i} onClick={() => handleRowClick(el)}>
                      <td width="180">
                        {new Date(el.created_at).toDateString()}
                      </td>
                      <td width="180">{el.username}</td>
                      <td width="180">
                        {el.first_name} {el.last_name}
                      </td>
                      <td width="120" style={{ textAlign: "end" }}>
                        {el.upload_stats.live_paid + el.upload_stats.live_free}
                      </td>
                      <td width="120" style={{ textAlign: "end" }}>
                        {el.upload_stats.in_review}
                      </td>
                      <td width="120" className="text-center">
                        {el.verified ? (
                          <p className="status live m-2">Live</p>
                        ) : (
                          <p className="status pending m-2">Pending</p>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllUsersCom;
