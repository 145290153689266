import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import axios from "axios";

import { isAuthenticated } from "./auth/auth";
import { refreshCSRF } from "./api/refreshCSRF";
import { logoutSession } from "./api/logoutSession";

// visitors
import Login from "./pages/Login";

// admin
import Dashboard from "./pages/admin/Dashboard";
import AllUsers from "./pages/admin/AllUsers";
import AllAlbums from "./pages/admin/AllAlbums";
import AllPhotos from "./pages/admin/AllPhotos";
import PhotosInReview from "./pages/admin/PhotosInReview";
import VerifyUsers from "./pages/admin/VerifyUsers";
import Settings from "./pages/admin/Settings";

// create a private route for admin
const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/dashboard", state: { from: props.location } }}
        />
      )
    }
  />
);

// create a private route for admin
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.request.responseURL !==
        "https://api-dev.pix.lk/v1/sessions" &&
      error.response.status === 401 &&
      error.response.request.responseURL !== "https://api.pix.lk/v1/sessions"
    ) {
      logoutSession();
    } else {
      if (
        error.config &&
        error.response &&
        error.response.status === 403 &&
        error.response.data.code === 1005
      ) {
        return refreshCSRF(
          (data) => {
            if (
              window.location.hostname === "localhost" ||
              window.location.hostname === "127.0.0.1"
            ) {
              localStorage("csrf_t", data.id);
            }

            error.config.headers["x-csrf-token"] = data.id;
            return axios.request(error.config);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }

    return Promise.reject(error);
  }
);

function App() {
  return (
    <Router>
      <Switch>
        <LoginRoute path="/" exact component={Login} />
        <PrivateRoute path="/dashboard" exact component={Dashboard} />
        <PrivateRoute path="/dashboard/all-users" exact component={AllUsers} />
        <PrivateRoute
          path="/dashboard/all-albums"
          exact
          component={AllAlbums}
        />
        <PrivateRoute
          path="/dashboard/all-photos"
          exact
          component={AllPhotos}
        />
        <PrivateRoute
          path="/dashboard/photos-in-review"
          exact
          component={PhotosInReview}
        />
        <PrivateRoute
          path="/dashboard/verify-users"
          exact
          component={VerifyUsers}
        />
        <PrivateRoute path="/dashboard/settings" exact component={Settings} />
      </Switch>
    </Router>
  );
}

export default App;
