import React from "react";
import { Modal } from "react-bootstrap";

import Close from "../../../images/close-white.png";

function RejectModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      size="md"
    >
      <Modal.Body>
        {/* <div
      className="modal fade"
      id="rejectModal"
      tabIndex="-1"
      aria-labelledby="rejectModalLabel"
      aria-hidden="true"
    > */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="rejectModalLabel">
                Are you sure?
              </h5>

              {/* <img
                src={Close}
                alt="close"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                width="50px"
                style={{ cursor: "pointer" }}
                id="rejectModalClose"
              /> */}
            </div>
            <div className="modal-body">
              <p>Do you want to reject this photo?</p>
            </div>
            <div className="modal-footer border-0 p-2">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={props.onHide}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => props.handleRejectPhoto(props.photoId)}
                style={{ width: 95 }}
              >
                {props.loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Reject"
                )}
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
}

export default RejectModal;
