import React, { useState, useEffect } from "react";

import { getPhoto } from "../../../api/getPhoto";

import FullPhotoPreview from "../modals/FullPhotoPreview";

function Photos(props) {
  const [thumbImage, setThumbImage] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getPhoto(
      props.id,
      false,
      (data) => {
        let x = URL.createObjectURL(data);
        setThumbImage(x);
      },
      (error) => {
        console.log(error);
      }
    );

    getPhoto(
      props.id,
      false,
      (data) => {
        let x = URL.createObjectURL(data);
        setThumbImage(x);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {thumbImage && (
        <img
          src={thumbImage}
          width="100"
          style={{ cursor: "zoom-in" }}
          onClick={handleClickOpen}
        />
      )}

      {open && (
        <FullPhotoPreview id={props.id} open={open} handleClose={handleClose} />
      )}
    </>
  );
}

export default Photos;
