import React, { useState } from "react";

import { Link, withRouter } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import Logo from "../../../images/logo.png";

import SideBar from "../sidebar/SideBar";

import { logoutSession } from "../../../api/logoutSession";

function Navbar(props) {
  const [sideBarDrawer, setSideBarDrawer] = useState(false);

  // drawer user account
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSideBarDrawer(open);
  };

  const closeDrawer = () => {
    setSideBarDrawer(false);
  };

  const handleLogout = () => {
    logoutSession();
  };

  return (
    <React.Fragment>
      <nav className="navbar sticky-top p-3 client-nav-bar">
        <MenuIcon className="menu-btn" onClick={toggleDrawer("left", true)} />
        <div className="logo-wrapper p-1">
          <Link to="/dashboard" className="text-white">
            <img src={Logo} className="logo" alt="Logo" />
          </Link>
        </div>
        <span className="ml-3" style={{ color: "white", fontSize: "22px" }}>
          Admin Dashboard
        </span>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <span
              className="nav-link mr-3"
              onClick={handleLogout}
              style={{ cursor: "pointer", color: "white", fontSize: "15px" }}
            >
              <i className="fas fa-power-off"></i> Logout
            </span>
          </li>
        </ul>
      </nav>

      <Drawer
        anchor={"left"}
        open={sideBarDrawer}
        onClose={() => closeDrawer(false)}
      >
        <div className="admin-sidebar-col drawer">
          <SideBar closeDrawer={closeDrawer} />
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default withRouter(Navbar);
