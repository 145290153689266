import axios from "axios";
import qs from "qs";

import { getCSRFToken } from "../auth/auth";

export const getAnUser = (uid, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/admin/users/${uid}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getAllUsers = (onSuccess, onError, verify_user = true) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/admin/users?include_verified=${verify_user}`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const verifyUser = (userId, isAccept, onSuccess, onError) => {
  const data = qs.stringify({
    accept: isAccept,
  });

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/admin/verification_requests/${userId}/accept`,
      data,
      {
        withCredentials: true,
        "Content-Type": "application/x-www-form-urlencoded;",
        headers: {
          "x-csrf-token": getCSRFToken(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};
