import axios from "axios";

import { isAuthenticated } from "../auth/auth";

export const getAnUser = (uid, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/admin/users/${uid}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": isAuthenticated(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getUserProfile = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/profile`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": isAuthenticated(),
      },
    })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
