import React, { useState, useEffect } from "react";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import AcceptModal from "../modals/AcceptModal";

import { getPhotosInReview } from "../../../api/getPhotosInReview";
import { rejectPhoto } from "../../../api/rejectPhoto";
import { acceptPhoto } from "../../../api/acceptPhoto";
import Photos from "./Photos";
import RejectModal from "../modals/RejectModal";
import userPhoto from "../../../images/user.png";

function PhotosInReviewCom() {
  const [photos, setPhotos] = useState([]);
  const [page, setPage] = useState(1);
  const [photoId, setPhotoId] = useState("");
  const [photoName, setPhotoName] = useState("");
  const [photoTags, setPhotoTags] = useState("");
  const [photoCat, setPhotoCat] = useState("");
  const [photoDesc, setPhotoDesc] = useState("");
  const [loading, setLoading] = useState("");

  const itemsPerPage = 2;

  useEffect(() => {
    getAllReviewPhotos();
  }, []);

  const getAllReviewPhotos = () => {
    getPhotosInReview(
      (data) => {
        setPhotos(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const handleAcceptPhoto = () => {
    setLoading(true);
    acceptPhoto(
      photoId,
      photoName,
      photoTags,
      photoCat,
      photoDesc,
      (data) => {
        setLoading(false);
        NotificationManager.success("Approved!", "Success");
        document.getElementById("acceptModalClose").click();
        getAllReviewPhotos();
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const handleRejectPhoto = (id) => {
    setLoading(true);
    rejectPhoto(
      id,
      (data) => {
        setLoading(false);
        NotificationManager.success("Rejected!", "Success");
        document.getElementById("rejectModalClose").click();
        getAllReviewPhotos();
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  // pagination
  const noOfPages = Math.ceil(photos.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = photos.slice(indexOfFirst, indexOfLast);

  return (
    <div className="content-col">
      <Breadcrumb title="Photos in Review" />

      <div className="container-fluid inner-content py-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Created At</th>
                  <th className="border-0">Image ID</th>
                  <th className="border-0 text-center">Status</th>
                  <th className="border-0 text-center">Image</th>
                  <th className="border-0 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length ? (
                  currentItems.map((el, i) => (
                    <React.Fragment key={i}>
                      <tr
                        className="text-light"
                        style={{ background: "#172343" }}
                      >
                        <td colSpan="5" style={{ position: "relative" }}>
                          <img
                            src={el.user.profile_photo || userPhoto}
                            alt="user photo"
                            className="profile-pic"
                          />
                          <p
                            className="mb-1"
                            style={{ position: "absolute", top: 10, left: 60 }}
                          >
                            {el.user.first_name} {el.user.last_name}{" "}
                            {el.user.email_confirmed ? (
                              <span
                                className="from-now"
                                style={{ color: "#19f047" }}
                              >
                                Email confirmed
                              </span>
                            ) : (
                              <span className="from-now text-danger">
                                Email Not Confirmed
                              </span>
                            )}
                          </p>
                          <p
                            className="mb-0"
                            style={{ position: "absolute", top: 28, left: 60 }}
                          >
                            {el.user.verified ? (
                              <span className="status live">Verified User</span>
                            ) : (
                              <span className="status pending">
                                Not Verified
                              </span>
                            )}
                          </p>
                        </td>
                      </tr>
                      {el.albums.map((item) => (
                        <React.Fragment key={item.id}>
                          <tr className="album-row">
                            <td colSpan="5">
                              <p className="mb-0">Album ID : {item.id}</p>
                              <p className="mb-0">Album Name : {item.name}</p>
                            </td>
                          </tr>
                          {item.photos.map((el) => (
                            <tr key={el.id}>
                              <td width="130">
                                {moment
                                  .utc(el.created_at)
                                  .local()
                                  .format("Do MMM YYYY")}
                                <p className="from-now">
                                  {moment.utc(el.created_at).local().fromNow()}
                                </p>
                              </td>
                              <td>{el.id}</td>
                              <td>
                                {el.status !== "IN_REVIEW" ? (
                                  <p className="status live">Reviewd</p>
                                ) : (
                                  <p className="status pending">In Review</p>
                                )}
                              </td>
                              <td>
                                <Photos id={el.id} key={el.id} />
                              </td>
                              <td>
                                <button
                                  className="btn btn-success btn-sm m-1"
                                  style={{ width: 80 }}
                                  data-toggle="modal"
                                  data-target="#acceptModal"
                                  onClick={() => {
                                    setPhotoId(el.id);
                                    setPhotoName(el.name);
                                    setPhotoTags(el.tags);
                                    // setPhotoCat(el.category.id);
                                    setPhotoDesc(el.desription);
                                  }}
                                >
                                  Accept
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-sm m-1"
                                  style={{ width: 80 }}
                                  data-toggle="modal"
                                  data-target="#rejectModal"
                                  onClick={() => {
                                    setPhotoId(el.id);
                                  }}
                                >
                                  Reject
                                </button>
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No Recods</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>

        <AcceptModal
          photoName={photoName}
          photoTags={photoTags}
          photoCat={photoCat}
          photoDesc={photoDesc}
          setPhotoName={setPhotoName}
          setPhotoTags={setPhotoTags}
          setPhotoCat={setPhotoCat}
          setPhotoDesc={setPhotoDesc}
          handleAcceptPhoto={handleAcceptPhoto}
          loading={loading}
        />
        <RejectModal
          photoId={photoId}
          handleRejectPhoto={handleRejectPhoto}
          loading={loading}
        />

        <NotificationContainer />
      </div>
    </div>
  );
}

export default PhotosInReviewCom;
