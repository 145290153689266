import { combineReducers } from "redux";
import authReducer from "./authReducer";
import createAdReducer from "./createAdReducer";

const rootReducer = combineReducers({
  user: authReducer,
  createAd: createAdReducer,
});

export default rootReducer;
