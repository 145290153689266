// import packages
import React, { useState } from "react";
import axios from "axios";
import classnames from "classnames";

import Breadcrumb from "../../admin/breadcrumb/Breadcrumb";
// import PhotosUpload from "./ad-components/PhotosUpload";
// import Preview from "./preview/Preview";

import { getCSRFToken } from "../../../auth/auth";

const initial = {
  curPassword: "",
  password: "",
  conPassword: "",

  curPasswordErr: "",
  passwordErr: "",
  conPasswordErr: "",
};

function SettingsCom(props) {
  const [form, setForm] = useState(initial);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  // validate
  const validate = () => {
    let curPasswordErr = "";
    let passwordErr = "";
    let conPasswordErr = "";

    if (form.curPassword === "") {
      curPasswordErr = true
        ? "Current password is required"
        : "වත්මන් මුරපදය අවශ්‍ය වේ";
    }

    if (form.password === "") {
      passwordErr = true ? "New password is required" : "නව මුරපදය අවශ්‍ය වේ";
    } else if (form.password.length < 8) {
      passwordErr = true
        ? "Password should be at least 8 characters long"
        : "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් විය යුතුය";
    }

    if (form.conPassword === "") {
      conPasswordErr = true
        ? "Confirmation password is required"
        : "තහවුරු කිරීමේ මුරපදය අවශ්‍ය වේ";
    } else if (form.password !== form.conPassword) {
      conPasswordErr = true ? "Passwords should match" : "මුරපද ගැලපිය යුතුය";
    }

    if (curPasswordErr || passwordErr || conPasswordErr) {
      setForm({ ...form, curPasswordErr, passwordErr, conPasswordErr });
      return false;
    }

    return true;
  };

  // submit change password
  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);

      const data = `oldPassword=${form.curPassword}&newPassword=${form.password}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/account/update_password`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${getCSRFToken()}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);

          setForm(initial);

          localStorage.setItem("at", res.data.token);
        })
        .catch((err) => {
          if (err.response.data.code === 1102) {
            setForm({
              ...form,
              curPasswordErr: true
                ? "Your current password is incorrect"
                : "ඔබගේ වර්තමාන මුරපදය වැරදිය",
            });
          } else if (err.response.data.code === 1106) {
            setForm({
              ...form,
              passwordErr: true ? "Password too short" : "මුරපදය ඉතා කෙටිය",
            });
          }

          setLoading(false);
        });
    }
  };

  return (
    <div className="content-col">
      <Breadcrumb title="Settings" />

      <div className="container-fluid inner-content">
        <div className="ad-form-sec">
          <form noValidate>
            <div className="form-row header">
              <div className="form-group col px-sm-2 mb-0">
                <p className="heading mb-0">Change Password</p>
              </div>
            </div>

            <div className="content" style={{ paddingTop: 10 }}>
              <div className="form-group col-6 p-0 3">
                <label htmlFor="curPassword">Current Password</label>
                <input
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": form.curPasswordErr,
                  })}
                  id="password"
                  name="curPassword"
                  onChange={handleChange}
                  value={form.curPassword}
                />
                <div className="invalid-feedback">{form.curPasswordErr}</div>
              </div>
              <div className="form-group col-6 p-0 3">
                <label htmlFor="password">New Password</label>
                <input
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": form.passwordErr,
                  })}
                  id="password"
                  name="password"
                  onChange={handleChange}
                  value={form.password}
                />
                <div className="invalid-feedback">{form.passwordErr}</div>
              </div>
              <div className="form-group col-6 p-0 3">
                <label htmlFor="conPassword">Confirm Password</label>
                <input
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": form.conPasswordErr,
                  })}
                  id="password"
                  name="conPassword"
                  onChange={handleChange}
                  value={form.conPassword}
                />
                <div className="invalid-feedback">{form.conPasswordErr}</div>
              </div>

              <button
                type="button"
                className="btn-submit mt-3"
                onClick={handleSubmit}
                style={{ width: "auto", float: "right" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SettingsCom;
