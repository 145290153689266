import React from "react";
import { Link } from "react-router-dom";

function DashboardCard(props) {
  return (
    <Link to={props.link}>
      <div className="dashboard-card" style={{ background: props.color }}>
        <div className="row m-0" style={{ height: "100%" }}>
          <div className="col-5 p-0 divider-right">
            <i className="far fa-edit"></i>
          </div>
          <div className="col-7 p-0">
            <div className="row m-0">
              <div className="col p-0">
                <p className="count">{props.count}</p>
              </div>
            </div>
            <div className="row m-0 divider-top">
              <div className="col p-0">
                <p className="heading">{props.title}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default DashboardCard;
