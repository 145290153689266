import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import PhotosInReviewCom from "../../components/admin/review/PhotosInReviewCom";

function PhotosInReview() {
  return (
    <AdminLayout>
      <PhotosInReviewCom />
    </AdminLayout>
  );
}

export default PhotosInReview;
