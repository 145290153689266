import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import AllUsersCom from "../../components/admin/all-users/AllUsersCom";

function AllUsers() {
  return (
    <AdminLayout>
      <AllUsersCom />
    </AdminLayout>
  );
}

export default AllUsers;
