import React, { Suspense } from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
const AllPhotosCom = React.lazy(() =>
  import("../../components/admin/all-photos/AllPhotosCom")
);

function AllPhotos(props) {
  return (
    <AdminLayout>
      <Suspense fallback={<div>Loading...</div>}>
        <AllPhotosCom {...props} />
      </Suspense>
    </AdminLayout>
  );
}

export default AllPhotos;
