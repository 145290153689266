import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Slide from "@material-ui/core/Slide";

import { getPhoto } from "../../../api/getPhoto";
import Close from "../../../images/close.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullPhotoPreview(props) {
  const [image, setImage] = useState("");

  useEffect(() => {
    getPhoto(
      props.id,
      true,
      (data) => {
        let x = URL.createObjectURL(data);
        setImage(x);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <img
        src={Close}
        alt="close"
        onClick={props.handleClose}
        className="fullsize-modal-close"
      />
      {/* <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.handleClose}
          aria-label="close"
          className="fullsize-modal-close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar> */}

      <List>
        <img src={image} alt="photo" className="fullsize-modal-img" />
      </List>
    </Dialog>
  );
}

export default FullPhotoPreview;
