import React from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import Close from "../../../images/close-white.png";

function SideBar(props) {
  return (
    <>
      <img
        src={Close}
        className="close-btn"
        alt="close"
        onClick={props.closeDrawer}
      />
      <div className="fixed-div pt-4">
        <div className="panel">
          <Link
            to="/dashboard"
            className={
              props.history.location.pathname === "/dashboard"
                ? "active-tab"
                : ""
            }
          >
            <i className="fas fa-tachometer-alt mr-2"></i> Dashboard
          </Link>
        </div>
        <div className="panel">
          <Link
            to="/dashboard/all-users"
            className={
              props.history.location.pathname === "/dashboard/all-users"
                ? // props.history.location.pathname === "/dashboard/all-photos"
                  "active-tab"
                : ""
            }
          >
            <i className="fas fa-users mr-2"></i> User Information
          </Link>
        </div>
        <div className="panel">
          <Link
            // to="/dashboard/all-users"
            className={
              props.history.location.pathname === "/dashboard/all-albums"
                ? // props.history.location.pathname === "/dashboard/all-photos"
                  "active-tab visible pl-5"
                : "invisible hide-menu"
            }
            // {props.history.location.pathname === "/dashboard/all-albums" ||
            //   props.history.location.pathname === "/dashboard/all-photos" ? style={{ visibility: "hidden" }}}
          >
            User Albums
          </Link>
        </div>
        <div className="panel">
          <Link
            to="/dashboard/all-users"
            className={
              props.history.location.pathname === "/dashboard/all-photos" ||
              props.history.location.pathname === "/dashboard/all-photos"
                ? "active-tab visible pl-5"
                : "invisible hide-menu"
            }
          >
            User Photos
          </Link>
        </div>
        {/* ////////////////////////////////////////////////////////////////////// */}
        {/* <div className="panel">
          <Link
            to="/userinformation"
            className={
              props.history.location.pathname === "/userinformation"
                ? "active-tab"
                : ""
            }
          >
            <i className="far fa-images mr-2"></i> Featured Images
          </Link>
        </div>
        <div className="panel">
          <Link
            to="/userinformation"
            className={
              props.history.location.pathname === "/userinformation"
                ? "active-tab"
                : ""
            }
          >
            <i class="far fa-file-image mr-2"></i> Find Image
          </Link>
        </div> */}
        <div className="panel">
          <Link
            to="/dashboard/settings"
            className={
              props.history.location.pathname === "/dashboard/settings"
                ? "active-tab"
                : ""
            }
          >
            <i className="fas fa-cog mr-2"></i> Settings
          </Link>
        </div>

        {/* ============================================================================ */}
        {/* <div className="panel">
          <Link
            to="/dashboard/all-users"
            className={
              props.history.location.pathname === "/dashboard/all-users" ||
              props.history.location.pathname === "/dashboard/all-photos"
                ? "active-tab"
                : ""
            }
          >
            <i className="far fa-image mr-2"></i> All Photos
          </Link>
        </div>
        <div className="panel">
          <Link
            to="/dashboard/photos-in-review"
            className={
              props.history.location.pathname === "/dashboard/photos-in-review"
                ? "active-tab"
                : ""
            }
          >
            <i className="far fa-image mr-2"></i> Photos in Review
          </Link>
        </div>
        <div className="panel">
          <Link
            to="/dashboard/verify-users"
            className={
              props.history.location.pathname === "/dashboard/verify-users"
                ? "active-tab"
                : ""
            }
          >
            <i className="fas fa-users mr-2"></i> Verify Users
          </Link>
        </div> */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isEmptyPost:
      Object.keys(state.user.userData).length === 0 &&
      state.user.userData.constructor === Object,
    user: state.user.userData,
  };
};

export default connect(mapStateToProps, null)(withRouter(SideBar));
