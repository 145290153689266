import axios from "axios";

import { getCSRFToken } from "../auth/auth";

export const getPhotosInReview = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/admin/photos_in_review`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};
