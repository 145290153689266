import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import VerifyUsersCom from "../../components/admin/verify-users/VerifyUsersCom";

function VerifyUsers() {
  return (
    <AdminLayout>
      <VerifyUsersCom />
    </AdminLayout>
  );
}

export default VerifyUsers;
