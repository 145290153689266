import axios from "axios";

import { getCSRFToken } from "../auth/auth";

export const logoutSession = () => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/v1/sessions`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
      },
    })
    .then((res) => {
      document.cookie =
        "auth_t=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem("csrf_t");
      window.location.href = "/";
    })
    .catch((err) => {
      console.log(err);
    });
};
