import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Breadcrumb from "../breadcrumb/Breadcrumb";

import { getAllUsers, verifyUser } from "../../../api/userAPI";
import RejectUserModal from "./RejectModal";
import { AiFillDownCircle, AiFillRightCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

function VerifyUsersCom() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleId, setVisibleId] = useState("");

  const itemsPerPage = 20;

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    getAllUsers(
      (data) => {
        data.map(
          (s, i) => (s.fullName = s.first_name.concat(" ", s.last_name))
        );
        setUsers(data);
      },
      (error) => {
        console.log(error);
      },
      false
    );
  };

  const onChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const verify = (isAccept, userId) => {
    setLoading(true);
    verifyUser(
      userId,
      isAccept,
      (data) => {
        if (isAccept) {
          NotificationManager.success("Approved!", "Success");
        } else {
          NotificationManager.success("Rejected!", "Success");
          document.getElementById("rejectUserModalClose").click();
        }
        setLoading(false);
        getUsers();
      },
      (error) => {
        setLoading(false);
        console.log(error);
        NotificationManager.error(
          `${error?.response?.data?.description} | Error code: ${error?.response?.data.code}`,
          "Unexpected error!"
        );
      }
    );
  };

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const handleExpand = (i) => {
    if (visible && visibleId === i) return setVisible(false);
    setVisible(true);
    setVisibleId(i);
  };

  const filterBySearch = users.filter((el) => {
    if (search) {
      return (
        (el.fullName &&
          el.fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (el.username &&
          el.username.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      );
    } else {
      return el;
    }
  });

  // pagination
  const noOfPages = Math.ceil(filterBySearch.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = filterBySearch.slice(indexOfFirst, indexOfLast);

  return (
    <div className="content-col">
      <Breadcrumb title="All Users" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                <label className="sr-only" htmlFor="search-by-id">
                  Search by name or code or city
                </label>
                <div className="input-group mb-0" style={{ width: "280px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    id="search-by-id"
                    placeholder="Name / Username"
                    value={search}
                    onChange={onChange}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <i
                    className="fas fa-search"
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "8px",
                      color: "#949494",
                      zIndex: 3,
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content py-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Username</th>
                  <th className="border-0">Name</th>
                  <th className="border-0">Role</th>
                  <th className="border-0 text-center">Status</th>
                  <th className="border-0 text-center">Action</th>
                  <th className="border-0"></th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length ? (
                  currentItems.map((el, i) => (
                    <tr key={i}>
                      <td width="200">{el.username}</td>
                      <td width="200">
                        {el.first_name} {el.last_name}
                      </td>
                      <td width="200">{el.role}</td>
                      <td className="text-center">
                        {el.verified ? (
                          <p className="status live">Live</p>
                        ) : (
                          <p className="status pending">Pending</p>
                        )}
                      </td>
                      <td className="text-center">
                        {!el.verified ? (
                          <>
                            <button
                              className="btn btn-success btn-sm m-1"
                              style={{ width: 80 }}
                              data-toggle="modal"
                              data-target="#paymentApproveModal"
                              onClick={() => {
                                verify(true, el.username);
                              }}
                            >
                              Verify
                            </button>
                            <button
                              className="btn btn-outline-danger btn-sm m-1"
                              style={{ width: 80 }}
                              data-toggle="modal"
                              data-target="#rejectUserModal"
                              onClick={() => setUserId(el.username)}
                            >
                              Reject
                            </button>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td width="300">
                        {visible && visibleId === i ? (
                          <AiFillDownCircle
                            size="20px"
                            onClick={() => handleExpand(i)}
                          />
                        ) : (
                          <AiFillRightCircle
                            size="20px"
                            onClick={() => handleExpand(i)}
                          />
                        )}

                        <div
                          style={{
                            display:
                              visible && visibleId === i ? "block" : "none",
                            width: "250px",
                            marginTop: "35px",
                          }}
                        >
                          <ul>
                            <li>
                              <strong>User Email: </strong>
                              {el.email}
                            </li>
                            <li>
                              <strong>User Role: </strong>
                              {el.role}
                            </li>
                            <li>
                              <strong>Total Uploads: </strong>
                              {el.upload_stats.total_uploaded}
                            </li>
                            <li>
                              <strong>In Review: </strong>
                              {el.upload_stats.in_review}
                            </li>
                            <li>
                              <strong>Live Free: </strong>
                              {el.upload_stats.live_free}
                            </li>
                            <li>
                              <strong>Live Paid: </strong>
                              {el.upload_stats.live_paid}
                            </li>
                            <li>
                              <strong>
                                <Link
                                  to={`/dashboard/all-albums?uid=${el.username}`}
                                >
                                  View User Albums
                                </Link>
                              </strong>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <RejectUserModal
        userId={userId}
        handleRejectUser={verify}
        loading={loading}
      />
      <NotificationContainer />
    </div>
  );
}

export default VerifyUsersCom;
